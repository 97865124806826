@import "@src/theme/variables";

.stages-warn-modal {
  .hr-line {
    display: none;
  }

  & &__container {
    max-width: 480px;
    padding: $space-2xl;
  }

  &__content {
    padding: $space-lg 0 0 ($space-lg + $space-2xl);
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $space-2xl;
  }
}
