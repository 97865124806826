@import "@src/theme/breakpoints";
@import "./home.common";

@include breakpoint-down(sm) {
  .stages-home {
    padding: 0 16px;
    gap: 0;

    &__wrapper {
      background: $dark-blue-2;
    }

    &__header {
      padding: $space-md $space-lg;
    }

    &__meeting {
      background-color: transparent;
      padding: 0;
    }

    &__meeting-container {
      width: 100%;
    }

    &__title {
      font-weight: 600;
      font-size: 28px;
      line-height: 32px;
    }

    &__actions {
      align-items: flex-start;
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }
}
