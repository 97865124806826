@import "@src/theme/variables";

.stages-empty-state {
  justify-content: start;
  margin: $space-3xl;

  .ting-text {
    color: inherit;
  }
}
