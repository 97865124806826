@import "@src/theme/variables";

$frame-border-radius: $rounded;

.stages-peer-frame {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $frame-border-radius;
  background-color: $dark-blue-2;

  &__speaking-state {
    position: absolute;
    border-radius: $frame-border-radius;
    inset: 0;

    &--active {
      border: 3px solid $mango;
    }
  }

  &__video-element {
    transform: scaleX(-1);
    height: 100%;
  }

  &__name {
    position: absolute;
    left: 8px;
    bottom: 8px;
  }

  &__raise-hand-indicator {
    background-color: $dark-blue-4;
    border-radius: $rounded-full;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 8px;
    left: 8px;

    > svg {
      color: $orange-10;
    }
  }

  &--width {
    width: 200px;
    height: calc((200px * 9) / 16);
  }

  &--width &__video-element {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(-1);
    height: 100%;
  }

  &:not(&--width) &__video-element {
    width: 100%;
    height: 100%;
  }
}
