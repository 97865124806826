@import "@src/theme/variables";

.stages-bottom-modal {
  position: relative;
  padding-top: $space-base;

  &__close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
