@import "@src/theme/variables";

.stages-poll-modal {
  display: flex;
  flex-direction: column;

  &.modalContainer {
    background-color: $dark-three;
    overflow: hidden;
  }

  .stages-header {
    padding: $space-lg;
    background-color: $dark-blue-2;
  }

  .stages-poll-frame {
    flex: 1;
  }

  .stages-create-poll {
    inset: 0;
  }
}
