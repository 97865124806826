@import "@src/theme/variables";

@mixin child($n) {
  &:nth-child(#{$n}) {
    left: ($n - 1) * -$space-lg;
  }
}

.stages-lobby-schedule-info-box {
  border-radius: $rounded;
  border: 1px solid $blue-zodiac;
  padding: $space-lg;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $space-lg;
  margin-bottom: $space-3-5xl;

  &--fade-in {
    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    animation: fade-in 2s;
  }

  &__icon {
    width: $space-3-5xl;
    height: $space-3-5xl;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $rounded-full;
    background-color: $dark-blue-3;
    border: $bordered-solid $dark-three;
    box-sizing: content-box;

    &--animated {
      svg {
        animation: rotate 1s infinite;
      }
    }
  }

  &__avatars {
    display: flex;
    align-items: center;
  }

  &__avatar-container {
    display: flex;
    border-radius: $rounded-full;
    border: $bordered-solid $dark-three;
    position: relative;

    @include child(2);
    @include child(3);
    @include child(4);
  }

  &__tool-tip {
    padding: $space-sm $space-md;
    color: $white;
  }

  &__more-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: $space-3-5xl;
    height: $space-3-5xl;
    background-color: $dark-blue-4;
  }
}
