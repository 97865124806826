@import "@src/theme/variables";

.stages-recurring-meeting-scheduler {
  /* stylelint-disable  */
  > .ting-text.type_main {
    color: $steel;
  }

  > :first-child {
    display: block;
  }
}
