@import "@src/theme/variables";

.stages-dropdown {
  position: relative;

  &__li {
    position: relative;

    &--divider {
      margin-top: $space-base + 1px;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -($space-xs + 1px);
        right: $space-base;
        left: $space-base;
        border-top: 1px solid $blue-zodiac;
      }
    }
  }

  &__content {
    position: absolute;
    max-width: 50vw;
    min-width: 150px;
    padding: $space-xs;
    border: 1px solid $dark-blue-6;
    border-radius: $rounded;
    background-color: $dark-blue-3;
    overflow: hidden;

    &:not(&--open) {
      opacity: 0;
      visibility: hidden;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .ting-button {
      width: 100%;
      height: 28px;
      padding: 0 $space-base;

      &__body {
        width: 100%;
        display: inline-flex;
        justify-content: left;
        text-wrap: nowrap;
      }
    }

    &--bottom-left {
      bottom: 0;
      left: 100%;
      transform: translate(-100%, 100%);
    }

    &--bottom-right {
      left: 0;
      bottom: 0;
      transform: translate(0%, 100%);
    }

    &--top-left {
      top: 0;
      left: 100%;
      transform: translate(-100%, -100%);
    }

    &--top-right {
      top: 0;
      left: 0;
      transform: translate(0%, -100%);
    }
  }

  &__item-content {
    width: 100%;
    display: inline-flex;
    align-items: center;

    .SvgIcon {
      &:not(:last-child):first-child {
        margin-right: $space-base;
      }

      &:not(:first-child):last-child {
        margin-left: auto;
      }
    }
  }
}
