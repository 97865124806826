@import "@src/theme/variables";

.stages-slide-down {
  &:not(:last-child) {
    border-bottom: 1px solid $blue-zodiac;
  }

  &__handler {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $space-lg;
    border: unset;
    outline: none;
    color: $white;
    background: none;
    cursor: pointer;
  }

  &__content {
    padding: 0 $space-lg $space-lg;
  }
}
