@import "@src/theme/variables";

.stages-participant-avatar {
  position: relative;
  display: inline-flex;

  &__visualizer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
    background-color: $blue-zodiac;
    border-radius: $rounded-full;

    &.active {
      transform: translate(-50%, -50%) scale(1.5);

      &--high {
        transform: translate(-50%, -50%) scale(1.9);
      }
    }
  }

  &__pulse {
    visibility: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: $rounded-full;

    &.active {
      visibility: visible;
      animation: avatar-pulse 2s infinite;
      border: 1px solid $blue-zodiac;
      z-index: 1;
    }
  }
}

@keyframes avatar-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}
