@import "@src/theme/variables";

.stages-stream-controller {
  &__live {
    display: flex;
    align-items: center;
    gap: $space-lg;
  }

  &__live-text {
    display: inline-flex;
    align-items: center;
    gap: $space-xs;
  }

  &__record {
    width: $space-base;
    height: $space-base;
    background-color: $steel;
    border-radius: $rounded-full;
  }
}
