@import "@src/theme/variables";

.stages-panel-controller {
  align-items: center;
  width: 100%;

  &,
  &__cell {
    display: flex;
  }

  &__cell {
    flex-grow: 1;
    gap: $space-base;
  }

  &--row,
  &--column {
    width: 100%;
  }

  &--column,
  &--column &__cell {
    height: 100%;
    flex-direction: column;
  }

  &__cell:last-child:not(:only-child) {
    justify-content: end;
  }

  &__separator {
    background-color: $blue-zodiac;
  }

  &--column &__separator {
    width: 100%;
    height: 1px;
  }

  &--row {
    gap: $space-sm;
  }

  &--row &__separator {
    width: 1px;
    height: $space-3xl;
  }

  .heading {
    display: inline-flex;
    align-items: center;
    margin: 0;

    .SvgIcon {
      margin-right: $space-base;
    }
  }
}
