@import "@src/theme/variables";

.stages-modal-peer-input {
  height: auto;
  flex: 1 1 40%;
  max-width: 49%;
  border: 1px solid $blue-zodiac;
  padding: $space-lg;
  text-align: left;
  justify-content: left;

  &:disabled {
    background-color: $dark-blue-3;
    border-color: transparent;

    .ting-text {
      color: $light-slate;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $space-base;
  }

  &__active-sources {
    font-size: 10px;
    font-weight: 500;
    gap: $space-base;
    color: $dark-blue-8 !important;
    border-color: $dark-blue-8 !important;
  }

  &--selected {
    border-color: $mango;
    outline: 3px solid $orange-3;

    &:hover:hover {
      background: none;
    }
  }

  .ting-button__body {
    width: 100%;
  }

  &:not(&--selected, :disabled):hover {
    background: none;
    border-color: $dark-blue-8;
  }
}
