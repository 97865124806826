@import "@ting/ting-ui-components/dist/styles/variables";
@import "./typography";

// spacing
$space-xxs: 2px;
$space-xs: 4px;
$space-sm: 6px;
$space-base: 8px;
$space-md: 12px;
$space-lg: 16px;
$space-xl: 20px;
$space-2xl: 24px;
$space-3xl: 32px;
$space-3-5xl: 40px;
$space-4xl: 48px;
$space-5xl: 64px;
$space-6xl: 80px;

// border

$bordered-xs-solid: 1px solid;
$bordered-sm-solid: 2px solid;
$bordered-solid: 3px solid;

// border radius
$rounded-xxs: 2px;
$rounded-xs: 4px;
$rounded-sm: 6px;
$rounded: 8px;
$rounded-xl: 16px;
$rounded-2xl: 20px;
$rounded-full: 50%;

// transition
$transition-func: cubic-bezier(0.4, 0, 0.2, 1);
$transition-time: 500ms;
