@import "@src/theme/variables";

.stages-main {
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: 100vh;
  position: relative;
  gap: $space-lg;

  &__view {
    flex-grow: 1;
    margin: 0 $space-lg;
  }

  &__control {
    margin: 0 $space-base;
    margin-bottom: $space-base;
  }
}
