@import "@src/theme/variables";

.stages-screen-view {
  width: 100%;
  height: 100%;
  display: flex;
  gap: $space-lg;
  align-items: center;
  flex-direction: column;
  position: relative;

  &__peers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: $space-base;
    width: 100%;
  }

  &__others {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $rounded;
    background-color: $dark-blue-2;
  }

  &__screen {
    width: 100%;
    height: 100%;
  }
}
