@import "@src/theme/variables";

.stages-toggle-with-content {
  display: flex;

  &__content {
    margin-left: $space-lg;
    flex: 1;
  }

  &__label-wrapper {
    display: flex;
    align-items: center;
  }

  &__label {
    margin-right: $space-base;
  }

  &__caption {
    margin-top: $space-xs;
  }

  &.right {
    flex-direction: row-reverse;
  }

  &.right &__content {
    width: fit-content;
    margin-left: 0;
    margin-right: $space-lg;
  }
}
