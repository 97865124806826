@import "@src/theme/variables";

.stages-rename-scene {
  width: 480px;
  max-width: 100%;

  &__container {
    padding: $space-2xl;
  }

  &__actions {
    display: flex;
    justify-content: end;
    gap: $space-base;
    padding: $space-2xl;
    border-top: 1px solid $blue-zodiac;
  }
}
