@import "@src/theme/variables";

.stages-object-properties {
  background: transparent;
  position: absolute;
  inset: 0 0 0 $space-lg;
  transition: background-color $transition-time $transition-func;
  border-radius: $space-base $space-base 0 0;

  &.entered,
  &.entering {
    background: rgb(0 0 0 / 30%);
  }

  &.exiting {
    background: transparent;
  }

  &.exited {
    background: transparent;
    opacity: 0;
    z-index: -99;
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    background-color: $dark-blue-2;
    border-radius: $space-base $space-base 0 0;
    flex-grow: 1;
    margin-top: $space-3xl;
    position: absolute;
    inset: 0;
    border: 1px solid $blue-zodiac;
    z-index: 999;
    transition: all $transition-time $transition-func;

    &.entered,
    &.entering {
      opacity: 1;
      transform: translateY(0);
    }

    &.exiting {
      opacity: 0;
      transform: translateY(100%);
    }

    &.exited {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  &__header {
    padding: $space-lg;
    border-bottom: $bordered-xs-solid $blue-zodiac;
  }

  &__content {
    overflow: auto;
  }

  &:only-child {
    .stages-object-properties__overlay {
      margin-top: 0;
      transition: none;
      border-radius: $space-base;
    }
  }
}
