@import "@src/theme/variables";

.stages-new-source {
  padding: $space-3xl 0;

  .stages-source-field {
    padding: 0 $space-3xl;

    &:not(:last-child) {
      padding-bottom: $space-lg;
      border-bottom: 1px solid $blue-zodiac;
    }

    &__input {
      max-width: 60%;
      width: 400px;
    }
  }
}
