@import "@src/theme/variables";

.stages-date-time-meeting {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: $space-lg;
  }

  &__date-time {
    display: flex;
    align-items: end;
    gap: $space-base;

    > :first-child {
      max-width: 203px;
    }

    > :last-child {
      max-width: 150px;
    }
  }

  &__input {
    max-width: 368px;
  }
}
