@import "reset-css/sass/reset";
@import "@src/theme/variables";
@import "breakpoints";

html,
body,
#root {
  height: 100%;
  font-size: 16px;
}

body {
  color: $white;
  background: $dark-blue-0;
  box-sizing: border-box;

  @extend %gen-p1-med;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

* {
  box-sizing: inherit;

  &::-webkit-scrollbar {
    width: $space-md;
  }

  &::-webkit-scrollbar-track {
    border-radius: $rounded;
    background-color: $dark-three;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $rounded;
    border: $space-xs solid $dark-three;
    background-color: $dark-blue-6;
  }
}

@font-face {
  font-family: Inter;
  src: url("../assets/Inter-VariableFont.ttf") format("truetype");
  font-weight: 400 800;
}

@include breakpoint-down(sm) {
  .modalOverlay.withMobileHeader {
    height: calc(100vh - 48px);
    margin-top: 48px;

    .modalContainer {
      min-height: calc(100vh - 48px);
    }
  }
}
