@import "@src/theme/breakpoints";
@import "./createRoom.common";

@include breakpoint-down(sm) {
  .stages-create-room {
    &__header {
      margin: $space-2xl 0;
    }

    &__content {
      padding-top: 0;
    }

    &__display-name-select,
    &__meeting-setting,
    &__participant-setting {
      margin-top: $space-lg;
    }

    &__participant-setting {
      padding-bottom: calc($space-3xl + $space-base);

      .stages-toggle-with-content:not(:first-child) {
        margin-top: $space-lg;
      }
    }

    &__footer {
      position: static;
    }

    &__footer-container {
      flex-direction: column-reverse;
      padding-top: 0;
    }

    &__footer-btn {
      &:first-child {
        margin-right: 0;
        margin-top: $space-base;
      }
    }
  }
}
