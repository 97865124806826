@import "@src/theme/breakpoints";
@import "@src/theme/variables";

.stages-home-layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $space-2xl $space-6xl;
  background: $dark-blue-2;

  &__avatar {
    margin-right: $space-base;
  }

  &__create-btn {
    margin-right: $space-base;
  }

  &__menu-btn {
    border: 0;
  }
}

@include breakpoint-down(sm) {
  .stages-home-layout-header {
    padding: $space-md $space-lg;

    &__actions {
      height: 24px;
    }
  }
}
