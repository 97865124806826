@import "@src/theme/variables";

.stages-existing-sources {
  &__list {
    padding: $space-3xl;
  }

  &__source {
    flex-direction: row-reverse;
    border: 1px solid $blue-zodiac;
    border-radius: $rounded;

    input:checked + ::before {
      --border-size: 12px !important;
    }

    &-label {
      width: 100%;
      padding: $space-base;

      .SvgIcon {
        margin-right: $space-base;
      }
    }

    &:not(:last-child) {
      margin-bottom: $space-base;
    }
  }
}
