@mixin font($weight: 400, $size, $lineHeight, $spacing: 0) {
  font-family: Inter, sans-serif;
  font-weight: $weight;
  line-height: $lineHeight;
  font-size: $size;
  letter-spacing: $spacing;
}

// Mobile H1 heading
%mob-h1-req {
  @include font(400, 34px, 40px, 0);
}

%mob-h1-med {
  @include font(500, 34px, 40px, 0);
}

%mob-h1-semibold {
  @include font(600, 34px, 40px, -0.34px);
}

%mob-h1-bold {
  @include font(700, 34px, 40px, -0.34px);
}

// Mobile H2 heading
%mob-h2-req {
  @include font(400, 28px, 32px, 0);
}

%mob-h2-med {
  @include font(500, 28px, 32px, 0);
}

%mob-h2-semibold {
  @include font(600, 28px, 32px, -0.28px);
}

%mob-h2-bold {
  @include font(700, 28px, 32px, -0.28px);
}

// Mobile H3 heading
%mob-h3-reg {
  @include font(400, 22px, 28px, 0);
}

%mob-h3-med {
  @include font(500, 22px, 28px, 0);
}

%mob-h3-semibold {
  @include font(600, 22px, 28px, -0.22px);
}

%mob-h3-bold {
  @include font(700, 22px, 28px, -0.22px);
}

// Mobile H4 heading
%mob-h4-reg {
  @include font(400, 20px, 24px, 0);
}

%mob-h4-med {
  @include font(500, 20px, 24px, 0);
}

%mob-h4-semibold {
  @include font(600, 20px, 24px, -0.2px);
}

%mob-h4-bold {
  @include font(700, 20px, 24px, -0.2px);
}

// Desktop H1 Heading
%desk-h1-reg {
  @include font(400, 38px, 52px, 0);
}

%desk-h1-med {
  @include font(500, 38px, 52px, 0);
}

%desk-h1-semibold {
  @include font(600, 38px, 52px, -0.38px);
}

%desk-h1-bold {
  @include font(700, 38px, 52px, -0.38px);
}

// Desktop H2 Heading
%desk-h2-reg {
  @include font(400, 30px, 40px, 0);
}

%desk-h2-med {
  @include font(500, 30px, 40px, 0);
}

%desk-h2-semibold {
  @include font(600, 30px, 40px, -0.3px);
}

%desk-h2-bold {
  @include font(700, 30px, 40px, -0.3px);
}

// Desktop H3 Heading
%desk-h3-reg {
  @include font(400, 24px, 32px, 0);
}

%desk-h3-med {
  @include font(500, 24px, 32px, 0);
}

%desk-h3-semibold {
  @include font(600, 24px, 32px, -0.24px);
}

%desk-h3-bold {
  @include font(700, 24px, 32px, -0.24px);
}

// Desktop H4 Heading
%desk-h4-reg {
  @include font(400, 20px, 28px, 0);
}

%desk-h4-med {
  @include font(500, 20px, 28px, 0);
}

%desk-h4-semibold {
  @include font(600, 20px, 28px, -0.2px);
}

%desk-h4-bold {
  @include font(700, 20px, 28px, -0.2px);
}

/* 
This is for short paragraphs with no more than four lines and is commonly used in 
components, input field labels, secondary navigation, buttons.
*/
// general P1 paragraph
%gen-p1-reg {
  @include font(400, 16px, 24px, 0);
}

%gen-p1-med {
  @include font(500, 16px, 24px, 0);
}

%gen-p1-semibold {
  @include font(600, 16px, 24px, 0);
}

%gen-p1-bold {
  @include font(700, 16px, 24px, 0);
}

// general P2 paragraph
%gen-p2-reg {
  @include font(400, 15px, 24px, 0);
}

%gen-p2-med {
  @include font(500, 15px, 24px, 0);
}

%gen-p2-semibold {
  @include font(600, 15px, 24px, 0);
}

%gen-p2-bold {
  @include font(700, 15px, 24px, 0);
}

/* 
This is for long paragraphs with more than four lines. Use also for longer body copy in components.
It is always left-aligned or centered, NEVER right-aligned.
*/
// general Body Large
%gen-bodyl-reg {
  @include font(400, 15px, 20px, 0);
}

%gen-bodyl-med {
  @include font(500, 15px, 20px, 0);
}

%gen-bodyl-semibold {
  @include font(600, 15px, 20px, 0);
}

%gen-bodyl-bold {
  @include font(700, 15px, 20px, 0);
}

// general Body medium
%gen-bodym-reg {
  @include font(400, 13px, 20px, 0);
}

%gen-bodym-med {
  @include font(500, 13px, 20px, 0);
}

%gen-bodym-semibold {
  @include font(600, 13px, 20px, 0);
}

%gen-bodym-bold {
  @include font(700, 13px, 20px, 0);
}

// general Body small
%gen-bodys-reg {
  @include font(400, 13px, 16px, 0);
}

%gen-bodys-med {
  @include font(500, 13px, 16px, 0);
}

%gen-bodys-semibold {
  @include font(600, 13px, 16px, 0);
}

%gen-bodys-bold {
  @include font(700, 13px, 16px, 0);
}

/* 
This is a multipurpose type style that can be used for error messages under the input fields and captions.
It should not be used for body copy.
*/
// general Body Large
%gen-cap-reg {
  @include font(400, 12px, 16px, 0);
}

%gen-cap-med {
  @include font(500, 12px, 16px, 0);
}

%gen-cap-semibold {
  @include font(600, 12px, 16px, 0);
}

%gen-cap-bold {
  @include font(700, 12px, 16px, 0);
}

// Buttons
%gen-btn-default {
  @include font(600, 15px, 24px, 0);
}

%gen-btn-medsmall {
  @include font(600, 13px, 20px, 0);
}

// Links
%gen-link-big {
  @include font(600, 15px, 24px, 0);
}

%gen-link-small {
  @include font(600, 13px, 20px, 0);
}

%gen-snackbar {
  @include font(600, 13px, 20px, 1.3px);
}

// Tags
%gen-tag-req {
  @include font(400, 8px, 10px, 0.8px);
}

%gen-tag-med {
  @include font(500, 8px, 10px, 0.8px);
}

%gen-tag-semibold {
  @include font(600, 8px, 10px, 0.8px);
}

%gen-tag-bold {
  @include font(700, 8px, 10px, 0.8px);
}
