@import "@src/theme/variables";

.stages-screen {
  display: flex;
  width: 100%;
  height: 100%;
  gap: $space-lg;
  background: $dark-three;
  padding: $space-lg;
  border-radius: $space-base;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
    width: 100%;
  }

  &__actions {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__canvas {
    position: absolute;
  }

  &__frame-wrapper {
    position: absolute;
    inset: 0;
    overflow: hidden;
  }

  canvas {
    width: 100%;
  }
}
