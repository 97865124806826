@import "@src/theme/variables";

.stages-audio-video-settings {
  &__content {
    padding: $space-lg;
    display: flex;
    flex-direction: column;
    gap: $space-2xl;
  }
}
