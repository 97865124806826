@import "@src/theme/variables";

.stages-display-name-select {
  display: flex;
  flex-direction: column;
  gap: $space-base;

  &__auth-needed {
    display: flex;
    align-items: center;
    color: $mango;
    white-space: nowrap;

    .ting-text {
      color: $mango;
    }

    svg {
      margin-left: 6px;
    }
  }

  &__caption {
    display: flex;
    gap: $space-base;
    color: $dark-blue-8;
  }

  &.stages-select-field__content {
    .stages-display-name-option {
      &__content {
        width: 100%;
        justify-content: space-between;
      }

      &__separator {
        display: none;
      }

      &__value {
        max-width: 60%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
