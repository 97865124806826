@import "@src/theme/variables";

.stages-schedule-meeting-message-modal {
  .modalContainer {
    padding: $space-lg;
    max-width: 480px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $space-4xl;
    padding: 0 $space-3-5xl $space-3-5xl $space-3-5xl;
  }

  &__message-wrapper {
    display: flex;
    flex-direction: column;
    gap: $space-lg;
    text-align: center;
  }

  &__action-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $space-base;
  }

  &__description {
    /* stylelint-disable  */
    &.ting-text.type_main {
      color: $dark-blue-8;
    }
  }

  &__icon {
    margin-top: $space-2xl;
    background-color: $dark-blue-3;
    width: 88px;
    height: 88px;
    border-radius: $rounded-full;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
