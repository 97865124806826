@import "@src/theme/variables";

.stages-add-source {
  &__content {
    width: 700px;
    max-width: 100%;
  }

  &__container {
    min-height: 40vh;
  }

  &__actions {
    display: flex;
    justify-content: end;
    gap: $space-base;
    padding: $space-3xl;
    border-top: 1px solid $blue-zodiac;
  }

  .react-tabs {
    &__tab {
      line-height: $space-2xl;
      padding-bottom: $space-base;
    }

    &__tab-list {
      border-color: $blue-zodiac;
      padding: 0 $space-3xl;
    }
  }

  .stages-header {
    margin-bottom: $space-lg;

    &__hr-line {
      display: none;
    }
  }
}
