@import "@src/theme/variables";

.stages-stream-form {
  width: 480px;
  max-width: 100%;
  padding: $space-2xl;

  &__container {
    padding: $space-xl 0;
  }

  &__actions {
    display: flex;
    justify-content: end;
    gap: $space-base;
    margin-top: $space-3xl;
  }

  &__indicator {
    display: flex;
    align-items: center;
    margin-right: auto;

    &-step {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      color: $mango;
      font-size: 10px;
      border-radius: $rounded-full;
      border: 1px solid $mango;

      &:not(:last-child) {
        margin-right: $space-xl;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          width: $space-xl + 1;
          height: 1px;
          background-color: $mango;
          transform: translateX(100%);
          z-index: -1;
        }
      }

      &--active {
        color: $dark-three;
        background-color: $mango;
      }
    }
  }

  &__input-group {
    &:not(:last-child) {
      margin-bottom: $space-lg;
    }
  }

  &__watch-stream {
    display: flex;
    justify-content: center;
  }
}
