@import "@src/theme/variables";

.stages-create-poll {
  background: transparent;
  position: absolute;
  inset: 0 0 0 $space-lg;
  transition: background-color $transition-time $transition-func;
  border-radius: $space-base $space-base 0 0;

  &.entered,
  &.entering {
    background: rgb(0 0 0 / 30%);
  }

  &.exiting {
    background: transparent;
  }

  &.exited {
    background: transparent;
    opacity: 0;
    z-index: -99;
  }

  &__content {
    display: flex;
    flex-direction: column;
    background-color: $dark-blue-2;
    border-radius: $space-base $space-base 0 0;
    flex-grow: 1;
    margin-top: 32px;
    position: absolute;
    inset: 0;
    border: 1px solid $blue-zodiac;
    z-index: 999;
    transition: all $transition-time $transition-func;

    &.entered,
    &.entering {
      opacity: 1;
      transform: translateY(0);
    }

    &.exiting {
      opacity: 0;
      transform: translateY(100%);
    }

    &.exited {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  &__form {
    flex-grow: 1;
    overflow: auto;
    padding-bottom: 73px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: $space-lg;
    border-bottom: $bordered-xs-solid $blue-zodiac;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: $space-lg;
    background-color: $dark-blue-2;
    border-top: $bordered-xs-solid $blue-zodiac;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    button {
      width: 100%;
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: $space-lg;
    padding: $space-2xl $space-lg;
    border-bottom: $bordered-xs-solid $blue-zodiac;

    &:last-child {
      border-bottom: 0;
    }

    .selectBox {
      width: 210px;
    }
  }

  &__settings-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__new-option {
    display: flex;
    gap: $space-base;
    align-items: flex-end;
  }

  &__add-more-btn {
    align-self: flex-start;
  }
}
