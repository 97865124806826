@import "@src/theme/variables";

.stages-recurring-meeting {
  display: flex;
  flex-direction: column;
  gap: $space-2xl;

  &__monthly {
    display: flex;
    gap: $space-lg;
    align-items: center;

    > :first-child {
      max-width: 112px;
    }

    > :last-child {
      margin-top: $space-3xl;

      /* stylelint-disable  */
      &.ting-text.type_main {
        color: $steel;
      }
    }
  }

  &__weekly {
    display: flex;
    flex-direction: column;
    gap: $space-lg;
  }

  &__days-of-week {
    display: flex;
    gap: $space-lg;
    flex-wrap: wrap;
  }

  &__end-by {
    max-width: 203px;
  }

  &__input {
    max-width: 368px;
  }
}
