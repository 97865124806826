.stages-crop-modal {
  width: 650px;
  padding: 70px 33px 33px;

  &__container {
    position: relative;
    width: 100%;
    height: 500px;
  }

  &__submit {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  @media screen and (max-width: 400px) {
    width: 100vw;

    .stages-crop-modal__container {
      height: 200px;
    }
  }
}
