@import "@src/theme/variables";

.stages-empty-scene {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: $space-lg;
  margin-top: $space-base;
  border-radius: $space-base;
  background-color: $dark-blue-2;
}
