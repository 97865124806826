@import "@src/theme/variables";

.stages-list-row {
  display: flex;
  align-items: center;
  padding: $space-base $space-lg;

  &__title {
    margin-inline-start: $space-lg;
  }

  &__post-elem {
    margin-inline-start: auto;
  }

  &__indicator {
    position: static;
    margin-inline-start: $space-base;

    &.badge .badge-content {
      position: static;
      background-color: $dark-blue-4;
      color: $dark-blue-8;
    }
  }
}
