/* stylelint-disable */
@import "@src/theme/variables";

.stages-select-field {
  .react-select__option__content {
    width: 100%;
    margin-right: 0;
  }
  .react-select__option__endIcon {
    display: none;
  }

  &__button {
    width: 100%;
    border: 1px solid $dark-blue-6;
    margin-top: $space-base;
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;

    .ting-button__body {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__modal-content {
    padding: $space-lg;
  }

  &__modal-option {
    display: block;
    outline: none;
    border: none;
    background: transparent;
    width: 100%;
    padding: 0;

    &:not(:first-child) {
      margin-top: $space-2xl;
    }
  }
}
