@import '@src/theme/variables';
@import '@src/theme/breakpoints';

.modalContainer {
  overflow: auto;
}

.modalContainer.fullscreen {
  border-radius: 0;
}

.stages-sign-up-modal {
  display: flex;
  flex-direction: column;
  max-width: 700px;

  &__header {
    padding: 32px;
    border-bottom: 1px solid $blue-zodiac;
  }

  &__header-title {
    display: flex;
    justify-content: space-between;
  }

  &__header-subtitle {
    display: flex;
    padding-top: 8px;
    gap: 2px;
  }

  .logo {
    margin-top: 1rem;
    margin-left: 0.5rem;
  }

  &__content {
    padding: 40px 32px 32px;
  }

  .action {
    margin-top: 24px;
    display: flex;
    justify-content: end;

    .complete-button {
      margin-left: 16px;
    }
  }
}

@include breakpoint-down(sm) {
  .stages-sign-up-modal {
    padding: 0;
    min-height: 100vh;

    .logo {
      display: none;
    }

    &__header {
      padding: 24px 16px;
    }

    &__step-1, &__step-2 {
      display: flex;
      flex-direction: column;

      &--hidden {
        display: none;
      }
    }

    &__header-subtitle {
      display: none;
    }

    &__content {
      display: flex;
      flex: 1;
      padding: 40px 16px 24px;

      .stages-upload-avatar {
        flex-direction: column;
      }
      .upload-profile .avatar {
        width: 100px;
        height: 100px;
      }
    }

    .upload-profile__field {
      margin-top: 32px;
      flex-grow: unset;
    }

    .action {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex-grow: 1;
      gap: 8px;

      .complete-button {
        margin-left: 0;
      }
    }

    &__step-2 {
      .action {
        flex-direction: column-reverse;
        justify-content: flex-start;
      }
    }

    .sign-up-text {
      letter-spacing: -0.2px;
    }

    .textField .field .input {
      height: 56px;
    }

    .button.size_medium {
      height: 56px;
    }

    .agreement-checkbox {
      .content {
        padding-left: 35px;
      }

      ::before {
        width: 40px;
        height: 40px;
        border-radius: 8px;
      }

      ::after {
        font-size: 35px;
      }
    }
  }
}

.username-rules {
  background-color: $blue-2;
  padding: 10px 16px 10px 32px;
  margin-top: 32px;
  border-radius: 8px;
  list-style: none;
  color: $blue-8;

  li::before {
    content: '\2022';
    color: $blue-8;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}

.success-check-icon {
  color: $green-8;
}

.upload-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;

  .avatar {
    width: 80px;
    height: 80px;
  }

  &__field {
    margin-top: 40px;
  }
}

.welcome-text {
  margin-top: 16px;
  margin-bottom: -24px;
}
