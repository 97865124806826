@import "@src/theme/variables";

.stages-chat-footer {
  padding: $space-lg;
  border-top: $bordered-xs-solid $blue-zodiac;
  background-color: $dark-blue-2;
  position: relative;

  &__reply-message {
    display: flex;
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: $dark-blue-2;
    padding: 12px 16px;
    z-index: 99999;

    > * {
      flex-shrink: 0;
    }
  }

  &__reply-message-content {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    margin: 0 $space-lg;
  }

  &__reply-message-text {
    word-break: break-word;
  }

  &__message-options {
    display: flex;
    align-items: center;
    gap: $space-base;
    color: $dark-blue-8;
    width: 100%;
    margin-bottom: $space-lg;
  }

  &__send-to {
    display: flex;
    align-items: center;
    flex-grow: 1;
    visibility: hidden;
  }

  &__message-visibility {
    margin-left: $space-base;
    width: 160px;
  }

  &__file-input {
    display: none;
  }
}
