@import "@src/theme/variables";

.stages-stream-frame {
  position: relative;
  flex-grow: 1;
  border-radius: $space-base;
  border: 1px solid $blue-zodiac;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;

  &__video-element {
    height: calc(100% + 2px);
    left: -1px;
    pointer-events: none;
    position: absolute;
    top: -1px;
    width: calc(100% + 2px);
    transform: scaleX(-1);
    object-fit: cover;

    &--hide {
      visibility: hidden;
    }
  }

  &__setting-row {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: center;
    gap: $space-lg;
    padding-bottom: $space-lg;
    background: linear-gradient(0deg, rgba($dark-three, 0.8) 0%, rgba($dark-three, 0%) 100%);
  }
}
