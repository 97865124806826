@import "@src/theme/variables";

.toast {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
  max-width: 386px;
  border-radius: $rounded;
  gap: $space-base;

  button,
  svg {
    flex-shrink: 0;
  }

  .content {
    display: flex;
    align-items: center;
    gap: $space-base;
  }

  &-small {
    padding: $space-base;
  }

  &-medium {
    padding: $space-md;
  }

  &-neutral {
    background-color: $dark-grey-blue;
  }

  &-brand {
    background-color: $mango;
  }

  &-info {
    background-color: $blue-8;
  }

  &-success {
    background-color: $green-8;
  }

  &-attention {
    background-color: $yellow-9;
  }

  &-danger {
    background-color: $red-7;
  }

  &-corepass {
    background-color: $corepass-blue-7;
  }

  &-brand,
  &-info,
  &-success,
  &-attention {
    svg,
    span {
      color: #171f2b !important;
    }
  }
}
