@import "@src/theme/variables";

.stages-join-room {
  &__container {
    margin: 0 $space-lg;
  }

  &__input {
    margin-top: $space-2xl;
  }

  &__submit {
    margin-top: $space-3-5xl;
    width: 100%;
  }
}
