@import "@src/theme/variables";

.stages-field-thumbnail {
  &__selector {
    max-width: 210px;
    width: 100%;
    margin-top: $space-base;
    border-radius: $rounded;
    overflow: hidden;

    button {
      border: 0;
      outline: none;
      color: $white;
    }
  }

  &__dd-box {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    gap: $space-base;
    background-color: $dark-blue-4;
    padding: $space-3xl $space-base;
    cursor: pointer;
  }

  &__selected {
    display: flex;
    padding: 0;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__actions {
    margin-top: $space-base;
  }
}
