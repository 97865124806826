@import "@src/theme/variables";
@import "@src/theme/breakpoints";
@import "./createRoomModal.common";

@include breakpoint-down(sm) {
  .stages-create-room-modal {
    padding: 0;
    min-height: calc(100vh - 48px);

    &__header {
      padding: $space-2xl $space-lg;
    }

    &__modal-container.modalOverlay .modalContainer {
      background-color: $dark-three;
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: $space-2xl $space-lg;
      margin-bottom: 0;
    }

    .action {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;
      flex-grow: 1;
      gap: $space-base;
      padding: $space-lg;

      .complete-button {
        margin-left: 0;
      }
    }
  }

  .stages-create-room-card {
    &__content {
      min-width: 100%;
    }
  }
}
