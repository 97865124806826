@import "@src/theme/variables";

.stages-permission-modal {
  & .modalContainer {
    padding: $space-lg;
  }

  &__content {
    max-width: 432px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $space-lg $space-lg $space-lg;
  }

  &__devices {
    display: flex;
    flex-direction: column;
    gap: $space-base;
    align-items: center;
  }

  &__device-icon {
    display: flex;
    align-items: center;
    background-color: $dark-blue-3;
    border-radius: $rounded-xl;
    padding: $space-base $space-lg;
    gap: $space-md;
  }

  &__title {
    text-align: center;
    margin-top: $space-3xl;
    margin-bottom: $space-2xl;
    line-height: 32px;
  }

  &__description {
    text-align: center;
    line-height: 24px;
    &.ting-text.type_main {
      color: $dark-blue-8;
    }
  }

  &__toggle-icon {
    height: 40px;
    color: $mango;

    svg:nth-child(2) {
      color: $steel;
      transform: translate(4px, -26px);
    }

    svg:nth-child(3) {
      color: $steel;
      transform: translateY(-19px);
    }

    svg:nth-child(4) {
      color: $steel;
      transform: translate(-12px, -9px);
    }
  }
}
