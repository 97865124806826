@import "@src/theme/variables";

.stages-source {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid transparent;
  border-radius: $rounded;
  position: relative;

  &__handler {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    cursor: pointer;
    font-size: 13px;
    line-height: 20px;
    color: $white;
    padding: $space-base;
    border: unset;
    border-radius: $rounded;
    overflow: hidden;
    background-color: transparent;

    .SvgIcon {
      flex-shrink: 0;
      margin-right: $space-base;
    }
  }

  &:hover &__handler {
    background-color: $dark-blue-2;
  }

  &--active {
    border-color: $mango;
  }

  &--hidden {
    > button {
      opacity: 0.5;
    }
  }

  &__actions {
    position: absolute;
    right: 0;
    z-index: 1;
  }

  &__name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: $space-xl;
  }

  .stages-dropdown__toggler {
    &:hover {
      background-color: transparent !important;
    }
  }

  &__toggle-icon {
    width: 20px !important;
    height: 20px !important;
  }

  &:not(:hover, &--open) &__actions {
    opacity: 0;
    visibility: hidden;
  }
}
