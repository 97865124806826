@import "@src/theme/variables";

.stages-poll-body {
  &__polls-container {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__polls-list {
    list-style: none;
    margin: 0;
    overflow: hidden auto;
    max-height: 100%;
    padding: 0;
    padding-bottom: 68px;
    flex-grow: 1;
    scroll-behavior: smooth;

    > * {
      position: relative;
      padding: 0 6px 4px;

      &:not(:first-child) {
        &::before {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          content: "";
          height: 1px;
          background: $blue-zodiac;
          width: 100%;
        }
      }
    }
  }

  &__add-poll-btn {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }

  &__not-active-state {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    min-height: 0;
    justify-content: center;
    align-items: center;
    padding: 0 48px;

    > .ting-text:last-of-type {
      color: $dark-blue-8;
      text-align: center;
      margin-top: -12px;
    }
  }

  &__poll-icon {
    display: flex;
    border-radius: 50%;
    padding: 10px;
    background-color: $dark-blue-3;
  }
}
