@import "@src/theme/variables";

.stages-header {
  &__is-modal--big {
    margin: $space-3xl;
  }

  &__is-modal--medium {
    margin: $space-2xl;
  }

  &__is-modal--small {
    margin: $space-lg;
  }

  &__hr-line {
    background-color: $blue-zodiac;
  }

  &__title-line {
    display: flex;
    align-items: center;
  }

  &__title {
    display: inline-flex;
    flex-grow: 1;
    text-wrap: nowrap;
    align-items: center;

    svg:first-child {
      margin-right: $space-lg;
    }

    &--mobile#{&}--small {
      @extend %mob-h4-semibold;
    }

    &--small {
      margin-right: $space-lg;

      @extend %desk-h4-semibold;
    }

    &--mobile#{&}--medium {
      @extend %mob-h3-semibold;
    }

    &--medium {
      margin-right: $space-lg;

      @extend %desk-h3-semibold;
    }

    &--mobile#{&}--big {
      @extend %mob-h2-semibold;
    }

    &--big {
      margin-right: $space-2xl;

      @extend %desk-h2-semibold;
    }
  }

  &__back-icon {
    width: $space-2xl !important;
    height: $space-2xl !important;
    margin-right: $space-lg;
    cursor: pointer;
  }

  &__close-icon {
    box-sizing: content-box;
    border-radius: $space-base;
    cursor: pointer;

    &--small {
      padding: $space-sm;
      width: $space-xl !important;
      height: $space-xl !important;
    }

    &--medium {
      padding: $space-base;
      width: $space-xl !important;
      height: $space-xl !important;
    }

    &--big {
      padding: $space-base;
      width: $space-2xl !important;
      height: $space-2xl !important;
    }

    &--filled {
      background-color: $dark-blue-4;
    }
  }

  &__paragraph {
    margin-top: $space-lg;

    &--big,
    &--medium {
      @extend %gen-p2-reg;
    }

    &--small {
      @extend %gen-bodym-reg;
    }

    &--back {
      margin-left: $space-lg + $space-2xl;
    }
  }
}
