@import "@src/theme/variables";
@import "@src/theme/breakpoints";
@import "@src/theme/mixins";

.modalContainer {
  overflow: auto;
}

.modalContainer.fullscreen {
  border-radius: 0;
}

.stages-create-room-modal {
  display: flex;
  flex-direction: column;
  max-width: 700px;

  &__header {
    padding: $space-3xl;
    border-bottom: $bordered-xs-solid $blue-zodiac;
  }

  &__header-title {
    display: flex;
    justify-content: space-between;
    line-height: 40px;
  }

  &__content {
    display: flex;
    gap: $space-lg;
    padding: $space-2xl;
    margin-bottom: 81px;
  }

  &__hr-line {
    background-color: $blue-zodiac;
  }

  .action {
    padding: $space-3xl;
    display: flex;
    justify-content: end;

    .complete-button {
      margin-left: $space-lg;
    }
  }
}

.stages-create-room-card {
  display: flex;
  cursor: pointer;
  align-items: stretch;
  border: $bordered-xs-solid;
  border-color: $blue-zodiac;
  border-radius: $rounded;
  transition: border-color 200ms $transition-func;

  &:not(.stages-create-room-card--selected):hover {
    border-color: $dark-blue-8;
  }

  &__content {
    border-radius: $rounded-sm;
    max-width: 288px;
    padding: $space-lg;
  }

  &--selected {
    @include border-with-shadow(3px, $orange-3);

    transition: box-shadow 100ms;
    border-color: $mango;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title-wrapper {
    margin-top: $space-base;
  }

  &__header-subtitle {
    display: flex;
    padding-top: $space-base;
    gap: $space-xxs;
  }

  &__icon-wrapper {
    display: flex;
  }

  &__tags-wrapper {
    display: flex;
    margin-top: -$space-base;
  }

  .tag {
    display: inline-block;
    margin-left: $space-base;
  }
}
