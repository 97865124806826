@import "@src/theme/variables";

.stages-source-field {
  display: flex;
  align-items: start;
  justify-content: space-between;

  > * {
    flex-grow: 1;
  }

  &__device-preview > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  > *:first-child {
    width: 45%;
    padding-right: 10%;
  }

  > *:last-child {
    width: 55%;
  }

  &:not(:last-child) {
    margin-bottom: $space-lg;
    padding-bottom: $space-lg;
    border-bottom: 1px solid $blue-zodiac;
  }

  &__label {
    width: 100%;
    padding: $space-base 0;
  }

  &__device-name {
    width: 100%;
    display: inline-flex;
    align-items: center;
    gap: $space-base;
    padding: $space-sm $space-base;
    border-radius: $rounded;
    border: 1px solid $dark-blue-6;
    background-color: $dark-blue-4;
  }

  &__audio-volume {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $space-base;
    width: 100%;
  }

  &__audio-volume-meter {
    width: 100%;
    padding: $space-base;
    border-radius: $rounded;
    background-color: $dark-three;
  }

  &__input {
    justify-content: flex-end;
  }

  &__device-preview {
    min-width: 300px;
    position: relative;
    background-color: $black;

    &:not(:only-child) {
      margin-top: $space-lg;
    }

    &::before {
      content: "";
      display: block;
      padding-top: 56.2%;
    }
  }

  &__file-selector {
    display: flex;
    gap: $space-base;
  }
}
