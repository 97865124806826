@import "@src/theme/variables";

.stages-cam-controller {
  display: flex;
  flex-direction: column;
  gap: $space-lg;
  align-items: center;

  &__buttons {
    position: relative;
  }

  &__attention-icon {
    position: absolute;
    right: -$space-xs;
    top: -$space-xs;
    z-index: 2;
    color: $yellow-9;
  }
}
