@import "@src/theme/variables";

.stages-home {
  display: flex;
  gap: 40px;
  flex: 1;
  padding: 0 40px 40px;

  &__wrapper {
    background: linear-gradient(74.47deg, $dark-three 6.36%, $dark-smooth 70.12%, $dark-three 96.21%);
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__header {
    background: transparent;
    padding: calc($space-3xl + $space-base);
  }

  &__meeting,
  &__studio {
    display: flex;
    padding: $space-lg;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: $dark-blue-0;
    border-radius: 8px;
  }

  &__meeting-container,
  &__studio-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
  }

  &__title {
    font-weight: 600;
    font-size: 50px;
    line-height: 64px;
    white-space: pre-wrap;
    letter-spacing: -0.02em;

    &-hero {
      position: relative;

      &--orange {
        color: $mango;
      }
    }
  }

  &__meeting-border {
    position: absolute;
    top: 56px;
    left: -8px;
  }

  &__studio-border {
    position: absolute;
    top: 56px;
    left: -24px;
  }

  &__caption {
    white-space: pre-wrap;
    line-height: 28px;
  }

  &__actions {
    display: flex;
    gap: $space-lg;
    margin-top: $space-xl;
  }

  &__start-meeting {
    width: 200px;

    &.selectBox {
      .react-select__control {
        background-color: $mango;
        border-style: none;
      }

      .react-select__single-value {
        color: $dark-three;
      }

      .react-select__indicator {
        padding: 0;
        margin-right: 24px;

        svg {
          width: 24px;
          height: 24px;
        }

        path {
          fill: $dark-three;
        }
      }

      .react-select__menu {
        background-color: $mango;
      }

      .react-select__option {
        color: $dark-three;
      }

      .react-select__option__content {
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        margin-right: 0;
      }

      .react-select__option:hover {
        background-color: $orange-9;
        color: $dark-three;
      }

      .react-select__option--is-focused,
      .selectBox .react-select__option--is-selected {
        color: $dark-three;
      }
    }
  }
}
