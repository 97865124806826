@import "@src/theme/variables";

.stages-scene {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid transparent;
  border-radius: $rounded;
  position: relative;

  &__handler {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    cursor: pointer;
    font-size: 13px;
    line-height: 20px;
    color: $white;
    padding: $space-base;
    border: unset;
    border-radius: $rounded;
    overflow: hidden;
    background-color: transparent;
  }

  &:hover &__handler {
    background-color: $dark-blue-2;
  }

  &--active {
    border-color: $mango;
  }

  &__color {
    display: inline-flex;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: $space-base;
    transition: background-color 300ms;

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      li:first-child {
        width: 100%;
      }
    }

    &-item {
      display: inline-flex;

      .ting-button {
        padding: $space-base;
        height: auto;

        &__body {
          line-height: 0;
        }
      }
    }

    &-content {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }

  &__name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: $space-xl;
  }

  &__actions {
    position: absolute;
    right: 0;
    z-index: 1;
  }

  .stages-dropdown__toggler {
    &:hover {
      background-color: transparent !important;
    }
  }

  &__toggle-icon {
    width: 20px !important;
    height: 20px !important;
  }

  &:not(:hover, &--open) &__actions {
    opacity: 0;
    visibility: hidden;
  }
}
