@import "@src/theme/variables";
@import "@src/theme/breakpoints";

.stages-name-auth-modal {
  &__content {
    padding: $space-3xl;
    max-width: 552px;
  }

  &__input {
    margin-top: $space-2xl;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    margin-top: $space-lg;
    gap: $space-lg;
  }

  &__submit {
    margin-top: $space-4xl - $space-base;
    width: 100%;
  }
}

@include breakpoint-down(sm) {
  .stages-name-auth-modal {
    &__content {
      padding: $space-lg;
    }
  }
}
