@import "@src/theme/variables";

.stages-modal-tabs {
  .tabs {
    padding: 0 $space-3xl;
    gap: $space-lg;
  }

  &__tab {
    height: auto;
    font-size: 15px;
    padding: 0 0 $space-base;
  }
}
