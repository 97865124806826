@import "@src/theme/variables";

.toaster-portal {
  margin: 0;
  padding: 0;
  list-style: none;
  position: fixed;
  display: flex;
  flex-direction: column-reverse;

  &.top-left {
    top: 0;
    left: 0;
  }

  &.top-right {
    top: 0;
    right: 0;
  }

  &.bottom-left {
    bottom: 0;
    left: 0;
  }

  &.bottom-right {
    bottom: 0;
    right: 0;
  }

  &.top-center,
  &.bottom-center {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.top-right,
  &.top-left,
  &.top-center {
    > li {
      margin: $space-xl $space-xl 0;
    }
  }

  &.bottom-right,
  &.bottom-left,
  &.bottom-center {
    > li {
      margin: 0 $space-xl $space-xl;
    }
  }
}
