@import "@src/theme/variables";

@keyframes indicator-scale {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) scale(2.5);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes indicator-blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.stages-modal-meet-header {
  display: flex;
  align-items: center;
  padding: $space-lg;
  gap: $space-base;

  &__indicator {
    position: relative;
    width: 20px;
    height: 20px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 8px;
      height: 8px;
      transform-origin: center;
      animation: 2s indicator-scale infinite;
      background-color: $dark-blue-3;
      border-radius: $rounded-full;
    }

    &::after {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: 1s indicator-blink infinite;
      border-radius: $rounded-full;
      background-color: $white;
    }
  }

  &__title {
    flex: 1;
  }
}
