@import "@src/theme/variables";

.stages-create-room {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__header {
    width: 100%;
    margin-top: $space-3xl;
    margin-bottom: $space-3xl;
  }

  &__content {
    width: 100%;
    flex: 1;
    padding-top: $space-lg;

    > * {
      margin-top: $space-2xl;
    }
  }

  &__footer {
    position: sticky;
    background-color: $dark-three;
    bottom: 0;
  }

  &__footer-container {
    padding-top: $space-2xl;
    padding-bottom: $space-2xl;
    display: flex;
    justify-content: flex-end;
  }

  &__footer-btn {
    &:first-child {
      margin-right: $space-base;
    }
  }

  &__input {
    max-width: 560px;
  }

  &__meeting-setting {
    .stages-toggle-with-content:not(:first-child) {
      margin-top: $space-lg;
    }
  }

  &__participant-setting {
    padding-bottom: $space-4xl;

    .stages-toggle-with-content:not(:first-child) {
      margin-top: $space-xl;
    }
  }

  &__divider {
    background-color: $blue-zodiac;
  }

  &__display-name-hint {
    display: flex;
    align-items: center;
    margin-top: $space-base;

    svg {
      margin-right: $space-base;
    }

    path {
      color: $steel;
    }
  }

  &__attendees {
    display: flex;
    flex-direction: column;
    gap: $space-base;

    .react-tags {
      background-color: $dark-blue-4;
      border: 1px solid $dark-blue-6;
      min-height: 142px;
    }
  }
}
