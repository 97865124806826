@import "@src/theme/variables";

.stages-chat-body {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  padding: $space-lg $space-base;
  padding-top: 0;
  display: flex;
  flex-direction: column;

  .popover-wrapper {
    padding: 0;
  }

  &__messages-list {
    list-style: none;
    margin: 0;
    overflow: hidden auto;
    max-height: 100%;
    padding: 0;
    flex-grow: 1;

    .chat-message__container,
    .file-item__container {
      width: 100%;
    }

    > div {
      scroll-behavior: smooth;
    }
  }

  &__not-active-state {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    min-height: 0;
    justify-content: center;
    align-items: center;

    > .ting-text:last-child {
      text-align: center;
      color: $dark-blue-8;
      margin-top: -12px;
    }
  }

  &__chat-icon {
    display: flex;
    border-radius: 50%;
    padding: 10px;
    background-color: $dark-blue-3;
  }

  &__scroll-down-btn {
    position: absolute;
    left: calc(50% - 16px);
    bottom: 8px;
    border-radius: 50%;
    padding: 0;
    transition: bottom 150ms ease-in-out;
    z-index: 99999999;

    &--hide {
      bottom: -32px;
    }
  }
}
