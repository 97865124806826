@import "@src/theme/variables";

.stages-studio {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__screen {
    flex-grow: 1;
  }

  &__tools {
    display: flex;
    flex-direction: row;
    gap: $space-lg;
  }

  &__div {
    position: relative;
    flex-grow: 0;
    height: 1px;
    background: $blue-zodiac;
    margin: $space-base 0;
    border: 0;
    cursor: ns-resize;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 5px;
      background: $steel;
      border-radius: $space-sm;
    }
  }
}
