@import "@src/theme/variables";

.stages-display-name-option {
  display: flex;
  justify-content: space-between;

  &__content {
    display: flex;
    width: 100%;

    span:nth-last-child(1) {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__title.ting-text {
    color: $dark-blue-8;
    white-space: nowrap;
  }

  &__separator.ting-text {
    color: $dark-blue-8;
    white-space: pre;
  }
}
