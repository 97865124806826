@import "@src/theme/variables";

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.stages-cam-off-frame {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $dark-blue-2;

  &__permission-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $space-2xl;
  }

  &__avatar {
    &--blink {
      .username-container span {
        margin-top: $space-lg;
        animation: 1s blink infinite;
      }
    }
  }
}
