@import "@src/theme/variables";

.stages-modal-source-input {
  max-width: 49%;
  height: auto;
  flex: 1 1 40%;
  justify-content: left;
  align-items: flex-start;
  text-align: left;
  border: 1px solid $blue-zodiac;
  padding: $space-lg;

  &__icon {
    margin-bottom: $space-xs;
  }

  &__caption {
    margin-top: $space-xs;

    .ting-text {
      line-height: $space-xl;
    }
  }

  &--selected {
    border-color: $mango;
    outline: 3px solid $orange-3;

    &:hover:hover {
      background: none;
    }
  }

  &:not(&--selected):hover {
    background: none;
    border-color: $dark-blue-8;
  }
}
