@import "@src/theme/variables";

.stages-chat-modal {
  display: flex;
  flex-direction: column;

  &.modalContainer {
    background-color: $dark-three;
  }

  .stages-header {
    padding: $space-lg;
  }

  .tabs {
    padding-top: 0;

    .tab {
      height: unset;
    }
  }

  .stages-header,
  .tabs {
    background-color: $dark-blue-2;
  }
}
