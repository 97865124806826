@import "@src/theme/variables";

.stages-peer-screen-frame {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__inner {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid $dark-three;
    border-radius: $space-base;
    overflow: hidden;
  }

  &__name {
    position: absolute;
    top: $space-base;
    left: $space-base;
  }

  &__video-element {
    width: 100%;
    height: 100%;
  }
}
