@import "@src/theme/variables";

.stages-source-frame {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  user-select: none;
  border-radius: $rounded-xxs;
  transform: unset !important;
  background: none;
  z-index: 100;

  &--active {
    border: 1px solid $mango;
  }

  &__resizer {
    position: absolute;
    bottom: -2px;
    right: -2px;
    width: 8px;
    height: 8px;
    padding: 0;
    z-index: 100;
    cursor: nwse-resize;
    border: unset;
    border-radius: 50%;
    background-color: $mango;

    &.bottom {
      bottom: -4px;
    }

    &.top {
      top: -4px;
    }

    &.left {
      left: -4px;
    }

    &.right {
      right: -4px;
    }
  }

  &--active:not(&--lock, &--retrieval):hover {
    cursor: move;
  }

  &--lock {
    border: 1px solid $cinnabar;
    cursor: default;

    .SvgIcon {
      position: absolute;
      bottom: 10px;
      right: 10px;
      color: $cinnabar;
    }
  }

  &--lock:not(&--active) {
    cursor: pointer;
    border: unset;
  }

  &--retrieval {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $space-base;
    cursor: default;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($dark-three, 0.9);
      z-index: -1;
    }
  }

  &--retrieval:not(&--active) {
    cursor: pointer;
    opacity: 0;
  }
}
