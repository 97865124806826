@import '@src/theme/variables';

.stages-upload-avatar {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      object-fit: cover;
    }

    .loading {
      position: absolute;
      z-index: 1;

      p {
        display: none;
      }
    }
  }
}
