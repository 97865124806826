$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1800px,
  xxxxl: 2140px,
);

@function breakpoint-min-width($size) {
  @if not map-has-key($breakpoints, $size) {
    @error '#{$size} is not a valid breakpoint';
  }

  $width: map-get($breakpoints, $size);

  @if $width < 0 {
    @error '#{$size} have a min width of negative';
  }

  @return $width;
}

@function breakpoint-max-width($size) {
  @if not map-has-key($breakpoints, $size) {
    @error '#{$size} is not a valid breakpoint';
  }

  $width: map-get($breakpoints, $size) - 1px;

  @if $width <= 0 {
    @error '#{$size} have a max width of zero or negative';
  }

  @return $width;
}

@mixin breakpoint-up($size) {
  @media (min-width: breakpoint-min-width($size)) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  @media (max-width: breakpoint-max-width($size)) {
    @content;
  }
}

@mixin breakpoint-between($min-size, $max-size) {
  $min-width: breakpoint-min-width($min-size);
  $max-width: breakpoint-max-width($max-size);

  @if $min-width >= $max-width {
    @error 'min width should be smaller that max width';
  }

  @media (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

@mixin breakpoint-only($size) {
  $sizes: map-keys($breakpoints);
  $index: index($sizes, $size);
  $length: length($sizes);

  @if $index < $length {
    $next-size: nth($sizes, $index + 1);

    @include breakpoint-between($size, $next-size) {
      @content;
    }
  }

  @else {
    @include breakpoint-up($size) {
      @content;
    }
  }
}
