@import "@src/theme/variables";

.stages-audio-volume {
  display: flex;
  gap: $space-md;

  &__bars {
    flex-grow: 1;
  }

  &__tags {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $space-xs;
  }

  &__meter {
    position: relative;
    display: flex;
    margin: $space-base 0;
    height: $space-xs;
    border-radius: $rounded;
    overflow: hidden;
    background: linear-gradient(90deg, #3eb446 0%, #3eb446 60%, #ffd258 60%, #ffd258 80%, #ff5858 80%, #ff5858 100%);

    &-active {
      border-radius: $rounded;
    }

    &-inactive {
      flex: 1 1;
      background-color: rgb(0 0 0 / 85%);
    }
  }

  &__slider {
    display: flex;
    border-radius: $rounded;
    overflow: hidden;

    input {
      width: 100%;
      height: $space-xs;
      margin: $space-base 0;
      outline: none;
      opacity: 0.7;
      border-radius: $rounded-xs;
      background: $blue-zodiac;
      transition: opacity 0.2s;
      appearance: none;

      &:hover {
        opacity: 1;
      }

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }

      &::-webkit-slider-thumb {
        width: 16px;
        height: 8px;
        cursor: ew-resize;
        background: $white;
        border-radius: $rounded-xs;
        box-sizing: content-box;
        appearance: none;
      }

      &::-moz-range-thumb {
        width: 16px;
        height: 8px;
        background: $white;
        cursor: ew-resize;
        border-radius: 5px;
      }

      &::-webkit-slider-runnable-track {
        color: #13bba4 !important;
        margin-top: -1px;
        appearance: none;
      }

      &::-moz-range-progress {
        background-color: $blue-zodiac !important;
      }

      &::-ms-fill-upper {
        background-color: #9a905d !important;
      }
    }
  }

  .ting-tag {
    min-width: 40px;
    font-size: 13px;
    border-radius: $rounded-xs;
  }
}
