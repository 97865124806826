@import "@src/theme/variables";

.stages-participant-name {
  display: flex;
  align-items: center;
  padding: $space-xs $space-base;
  gap: $space-base;
  border-radius: $rounded-xs;
  background-color: $dark-blue-4;

  &__mute-icon {
    height: 20px;
    display: flex;
    align-items: center;

    > * {
      color: $red-7;
    }
  }
}
