@import "@src/theme/variables";

.stages-audio-source {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  border: 1px solid transparent;
  border-radius: $rounded;
  position: relative;

  &__name {
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    margin-bottom: $space-xs;

    .SvgIcon {
      flex-shrink: 0;
    }
  }

  &__name-text {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: $space-base;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    gap: $space-lg;
  }

  &__bars {
    flex-grow: 1;
  }

  &__actions {
    display: flex;
  }
}
