@import "@src/theme/variables";

.stages-meeting-header {
  background-color: $dark-three;
  padding: $space-base $space-lg;
  display: flex;
  gap: $space-3xl;
  align-items: center;
  justify-content: space-between;

  &__name-timer {
    display: flex;
    flex-direction: column;
    min-width: 0;
  }

  &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__buttons {
    display: flex;
    gap: $space-base;
  }
}
