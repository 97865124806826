@import "@src/theme/variables";

.stages-gallery-view {
  width: 100%;
  height: 100%;
  display: flex;
  gap: $space-base;
  align-items: center;
  place-content: center center;
  flex-wrap: wrap;
}
