.stages-chat-emoji-picker {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 105%;
  z-index: 99999999999999999;

  > div {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  em-emoji-picker {
    --rgb-background: 54, 65, 84;

    width: 100%;
  }
}
