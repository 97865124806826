@use "sass:map";
@import "@src/theme/variables";
@import "@src/theme/breakpoints";

.stages-lobby {
  background-color: $dark-three;

  &__small-screen-header {
    display: none;

    @include breakpoint-down(xl) {
      display: block;
    }
  }

  &__content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $space-2xl $space-lg;
    flex-direction: column;
    gap: $space-3xl;

    .stages-lobby-input-settings-row {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
    }
  }

  &__stream-content {
    width: 100%;
  }

  &-info {
    width: 100%;

    &__input {
      margin-bottom: $space-lg;
    }

    &__submit {
      margin-top: $space-2xl;
      width: 100%;
    }
  }
}
