@import "@src/theme/variables";

.stages-checkbox-dropdown {
  display: flex;
  flex-direction: column;
  padding: 0 $space-3xl;

  &:not(:last-child) {
    border-bottom: 1px solid $blue-zodiac;
  }

  &__toggler {
    display: flex;
    align-items: center;
    width: 100%;
    gap: $space-lg;
    padding: $space-2xl 0;
  }

  &__content {
    padding-bottom: $space-2xl;

    &:not(&--open) {
      height: 0;
      padding: 0;
      visibility: hidden;
    }
  }

  &__not-available {
    white-space: nowrap;
    margin-left: auto;
    font-size: 10px;
    font-weight: 500;
    gap: $space-base;
    color: $dark-blue-8 !important;
    border-color: $dark-blue-8 !important;
  }
}
