@import "@src/theme/variables";

.stages-studio-panel {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: calc(100% / 33.333);
  background: $dark-three;
  border-radius: $space-base;

  &__header {
    padding: $space-md $space-lg;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding: 0 $space-base $space-md;
  }
}
