@import "@src/theme/variables";
@import "@src/theme/breakpoints";
@import "./toggleWithContent.common";

@include breakpoint-down(sm) {
  .stages-toggle-with-content {
    flex-direction: row-reverse;
    justify-content: space-between;

    &__content {
      width: fit-content;
      margin-left: 0;
      margin-right: $space-lg;
    }
  }
}
