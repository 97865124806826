@import "@src/theme/variables";

.stages-transform-slide {
  &__group {
    display: flex;
    align-items: center;
    gap: $space-base;

    > * {
      width: auto;
    }
  }

  > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $space-lg;

    &:not(:last-child) {
      margin-bottom: $space-base;
    }
  }

  &__input-group {
    position: relative;

    > span {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      font-size: 13px;
      color: $dark-blue-6;
    }

    .input.input {
      max-width: 100px;
      padding: $space-xxs $space-base;
    }
  }

  &__position &__group {
    gap: 47px;
  }

  &__flip {
    .ting-button {
      min-width: 66px;
    }
  }
}
