@import "@src/theme/variables";

.stages-alignment-slide {
  display: flex;
  flex-wrap: wrap;
  gap: $space-lg;

  &__group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ting-button {
      width: 50px;
    }
  }

  &__alignment {
    width: 100%;
  }

  &__order,
  &__layout {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .alignment-actions {
    .ting-button {
      width: 100%;
    }
  }
}
